"use client";

import packageJson from "../../package.json";
import ky from "ky";

const controller = new AbortController();
const { signal } = controller;

const headers = {
  accept: "application/json",
  "user-agent": `${packageJson.name}/${packageJson.version}`,
  "content-type": "application/json",
};

const getBaseUrl = (type) => {
  const BASE_URLS = {
    notification: process.env.NEXT_PUBLIC_API_NOTIFICATION_BASE_URL + "/v1",
    default: process.env.NEXT_PUBLIC_API_BASE_URL + "/v1",
    hub: process.env.NEXT_PUBLIC_API_HUB_BASE_URL + "/api",
  };

  return BASE_URLS[type];
};

const createFetchInstance = (type) => {
  return ky.create({
    headers,
    prefixUrl: getBaseUrl(type),
    timeout: 60000,
    signal,
    hooks: {
      beforeError: [
        async (error_) => {
          const error = error_;
          if (error.name === "HTTPError") {
            const errorJson = await error.response.json();
            if (errorJson.message !== "Unauthorized") {
              console.error(errorJson?.message);
            }
          }
          return error_;
        },
      ],
    },
  });
};

const clientFetch = async (params, type = "default") => {
  let url;
  let options;

  if (typeof params === "string") {
    url = params;
    options = undefined;
  } else {
    [url, options] = params;
  }
  const fetchFn = createFetchInstance(type);

  return await fetchFn(url, {
    ...(options || {}),
  }).json();
};

export default clientFetch;
