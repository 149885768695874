import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-hot-toast@2.4.1_csstype@3.1.3_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/react-hot-toast/dist/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/swr@2.3.3_react@18.2.0/node_modules/swr/dist/index/config-client-Bi4rgVRk.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/aos@2.3.4/node_modules/aos/dist/aos.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/swiper@11.0.5/node_modules/swiper/swiper.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/swiper@11.0.5/node_modules/swiper/modules/free-mode.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/swiper@11.0.5/node_modules/swiper/modules/effect-coverflow.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/swiper@11.0.5/node_modules/swiper/modules/navigation.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.jsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/AOSWrapper.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ProgressBarWrapper.jsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/lib/ClientFetch.js")